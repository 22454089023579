// import React, { useState, useCallback, useEffect } from "react";
// import ApiCall from "../../api/callApi";

// const ResignationFrom = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   const [selectedFiles, setSelectedFiles] = useState([]); // To store uploaded files
//   const [aadharFiles, setAadharFiles] = useState({ front: null, back: null }); // To store Aadhar images

//   const handleFileChange = (e) => {
//     const files = Array.from(e.target.files);
//     setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
//   };

//   const handleAadharFileChange = (e, type) => {
//     const file = e.target.files[0];
//     setAadharFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
//   };

//   const formData = {
//     name: "",
//     fatherName: "",
//     mobNo: "",
//     category: "",
//     address: "",
//     pinCode: "",
//     cardioArea: "",
//     adharNo: "", // Aadhar Number
//   };

//   const [value, setValue] = useState(formData);

//   const submitted = async (e) => {
//     e.preventDefault();

//     // Check if all fields are filled
//     if (
//       value.name &&
//       value.fatherName &&
//       value.mobNo &&
//       value.category &&
//       value.address &&
//       value.pinCode &&
//       value.cardioArea &&
//       value.adharNo && // Ensure Aadhar Number is provided
//       selectedFiles.length > 0 &&
//       aadharFiles.front &&
//       aadharFiles.back // Ensure Aadhar images are uploaded
//     ) {
//       const formData = new FormData();
//       formData.append("name", value.name);
//       formData.append("father_name", value.fatherName);
//       formData.append("mob_no", value.mobNo);
//       formData.append("category", value.category);
//       formData.append("address", value.address);
//       formData.append("pin_code", value.pinCode);
//       formData.append("aadhar_no", value.adharNo); // Aadhar Number

//       // Append each file to FormData
//       selectedFiles.forEach((file) => {
//         formData.append("files", file);
//       });

//       // Append Aadhar images
//       formData.append("aadhar_front", aadharFiles.front);
//       formData.append("aadhar_back", aadharFiles.back);

//       try {
//         await ApiCall(formData, "post", "job_apply", job_applyCallback);
//       } catch (error) {
//         console.error("Error submitting application:", error);
//         alert("An error occurred. Please try again later.");
//       }
//     } else {
//       alert("Please fill in all the required fields.");
//     }
//   };

//   const job_applyCallback = useCallback((response) => {
//     if (response.status === 200) {
//       console.log(response);
//       setValue(formData);
//       alert(response.data.message);
//       setSelectedFiles([]); // Clear the selected files
//       setAadharFiles({ front: null, back: null }); // Clear Aadhar files
//     } else {
//       console.log("API error:", response.status);
//     }
//   }, []);

//   return (
//     <div className="custom_container container py-5 my-4">
//       <h2 className="fw-bold font_size text_gradient ff_inter text-center fs-1 mb-0">
//         FREE SKILL TRAINING CENTRE
//       </h2>
//       <p className="ff_inter text-center text_dark_grey fs-5 mb-0 mt-3">
//         Skill <span>BestTraining</span> Registration
//       </p>

//       <div className="careers_box mt-4 mx-auto">
//         <div className="careers_box rounded-2 border mx-auto mt- p-3 mb-4">
//           <h6 className=" fs_md">COURSES : </h6>
//           <div className="d-flex flex-wrap gap-3 align-items-center mt-3">
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               1. Graphics Designer
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               2. Tally
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               3. MS Office
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               4. Office Executive
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               5. Office Operation Executive
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               6. AI
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               7. Typing (Eng.+Hindi) English Spoken
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               8. Shorthand Typing,
//             </p>
//             <p className="ff_inter text-center text_dark_grey fs_md mb-0">
//               9. Steno
//             </p>
//           </div>
//         </div>
//         <form onSubmit={submitted}>
//           <div className="row">
//             <div className="col-md-6">
//               <input
//                 required
//                 className="w-100 careers_input"
//                 type="text"
//                 placeholder="Name"
//                 onChange={(e) => setValue({ ...value, name: e.target.value })}
//                 value={value.name}
//               />
//             </div>
//             <div className="col-md-6 mt-4 mt-md-0">
//               <input
//                 required
//                 className="w-100 careers_input"
//                 type="text"
//                 placeholder="Father Name"
//                 onChange={(e) =>
//                   setValue({ ...value, fatherName: e.target.value })
//                 }
//                 value={value.fatherName}
//               />
//             </div>
//           </div>
//           <div className="w-100 mt-4">
//             <input
//               required
//               className="w-100 careers_input"
//               type="number"
//               placeholder="Mob No."
//               onChange={(e) => setValue({ ...value, mobNo: e.target.value })}
//               value={value.mobNo}
//             />
//           </div>
//           <div className="w-100 mt-4">
//             <input
//               required
//               className="w-100 careers_input"
//               type="text"
//               placeholder="Category"
//               onChange={(e) => setValue({ ...value, category: e.target.value })}
//               value={value.category}
//             />
//           </div>
//           <div className="w-100 mt-4">
//             <input
//               required
//               className="w-100 careers_input"
//               type="text"
//               placeholder="Address"
//               onChange={(e) => setValue({ ...value, address: e.target.value })}
//               value={value.address}
//             />
//           </div>
//           <div className="w-100 mt-4">
//             <input
//               required
//               className="w-100 careers_input"
//               type="text"
//               placeholder="Pin Code"
//               onChange={(e) => setValue({ ...value, pinCode: e.target.value })}
//               value={value.pinCode}
//             />
//           </div>
//           <div className="w-100 mt-4">
//             <input
//               required
//               className="w-100 careers_input"
//               type="number"
//               placeholder="Aadhar Number"
//               onChange={(e) => setValue({ ...value, adharNo: e.target.value })}
//               value={value.adharNo}
//             />
//           </div>
//           <select
//             required
//             className="w-100 mt-4 p-3 careers_input"
//             onChange={(e) => setValue({ ...value, cardioArea: e.target.value })}
//             value={value.cardioArea}
//           >
//             <option value="">Select Center</option>
//             <option value="Chira Chas (Bokaro)">Chira Chas (Bokaro)</option>
//             <option value="Chandankiyari (Bokaro)">
//               Chandankiyari (Bokaro)
//             </option>
//             <option value="Chas College (Bokaro)">Chas College (Bokaro)</option>
//             <option value="Bermo (Bokaro)">Bermo (Bokaro)</option>
//             <option value="Petarwar (Bokaro)">Petarwar (Bokaro)</option>
//             <option value="Gomia (Bokaro)">Gomia (Bokaro)</option>
//             <option value="Jaridih (Bokaro)">Jaridih (Bokaro)</option>
//           </select>

//           <div className="w-100 mt-4">
//             <label className="d-block mb-2 ff">Upload Aadhar Front Image</label>
//             <input
//               required
//               type="file"
//               className="careers_input w-100"
//               accept=".jpg, .jpeg, .png"
//               onChange={(e) => handleAadharFileChange(e, "front")}
//             />
//           </div>

//           <div className="w-100 mt-4">
//             <label className="d-block mb-2 ff">Upload Aadhar Back Image</label>
//             <input
//               required
//               type="file"
//               className="careers_input w-100"
//               accept=".jpg, .jpeg, .png"
//               onChange={(e) => handleAadharFileChange(e, "back")}
//             />
//           </div>

//           <button
//             type="submit"
//             className="mt-4 btn_login text-white w-100 ff_inter bg_gradient border-0 p-2 px-lg-3 py-lg-2"
//           >
//             Register Now
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ResignationFrom;

import React, { useState, useCallback, useEffect, useRef } from "react";
import ApiCall from "../../api/callApi";
import "./footer.css";

const ResignationFrom = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [loading, setLoading] = useState(false); // State to manage loading
  const [studentId, setStudentId] = useState(false); // State to manage loading
  const [selectedFiles, setSelectedFiles] = useState([]); // To store uploaded files
  const [aadharFiles, setAadharFiles] = useState({ front: null, back: null }); // To store Aadhar images

  //   const handleFileChange = (e) => {
  //     const files = Array.from(e.target.files);
  //     setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  //   };

  //   const handleAadharFileChange = (e, type) => {
  //     const file = e.target.files[0];
  //     setAadharFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
  //   };

  const frontFileInputRef = useRef(null);
  const backFileInputRef = useRef(null);

  const handleAadharFileChange = (e, type) => {
    const file = e.target.files[0];
    setAadharFiles((prevFiles) => ({ ...prevFiles, [type]: file }));
  };

  const formData = {
    name: "",
    f_name: "", // Father Name field mapped to f_name
    phone: "", // Mobile number mapped to phone
    category: "",
    address: "",
    pinCode: "",
    adharNo: "", // Aadhar Number
    center: "", // Center field
    front: null, // Aadhar front image
    back: null, // Aadhar back image
  };

  const [value, setValue] = useState(formData);

  const submitted = async (e) => {
    setLoading(true); // Start loading

    // Check if all fields are filled
    if (
      value.name &&
      value.f_name &&
      value.phone &&
      value.category &&
      value.address &&
      value.pinCode &&
      value.center &&
      value.adharNo && // Ensure Aadhar Number is provided
      //   selectedFiles.length > 0 &&
      aadharFiles.front &&
      aadharFiles.back // Ensure Aadhar images are uploaded
    ) {
      const formData = new FormData();
      formData.append("name", value.name);
      formData.append("f_name", value.f_name); // Use f_name as per the requirement
      formData.append("phone", value.phone); // Use phone as per the requirement
      formData.append("category", value.category);
      formData.append("address", value.address);
      formData.append("pinCode", value.pinCode);
      formData.append("adharNo", value.adharNo); // Aadhar Number
      formData.append("center", value.center); // Aadhar Number

      formData.append("front", aadharFiles.front); // Aadhar front image
      formData.append("back", aadharFiles.back); // Aadhar back image

      try {
        await ApiCall(formData, "post", "registration", job_applyCallback); // Updated API endpoint
      } catch (error) {
        console.error("Error submitting application:", error);
        alert("An error occurred. Please try again later.");
      } finally {
        setLoading(false); // Stop loading after the request completes
      }
    } else {
      alert("Please fill in all the required fields.");
      setLoading(false); // Stop loading in case of validation failure
    }
  };

  const job_applyCallback = useCallback((response) => {
    if (response.status === 200) {
      console.log(response);
      setValue(formData);
      alert(response?.data?.message);
      setStudentId(response?.data?.registrationId);
      //   setSelectedFiles([]); // Clear the selected files
      setAadharFiles({ front: null, back: null }); // Clear Aadhar files
    } else {
      console.log("API error:", response.status);
    }
  }, []);

  return (
    <div className="custom_container container py-5 my-4">
      <h2 className="fw-bold font_size text_gradient ff_inter text-center fs-1 mb-0">
        FREE SKILL TRAINING CENTRE
      </h2>
      <p className="ff_inter text-center text_dark_grey fs-5 mb-0 mt-3">
        Registration Form
      </p>

      <div className="careers_box mt-4 mx-auto">
        {/* <div className="careers_box rounded-2 border mx-auto mt- p-3 mb-4">
          <h6 className="fs_md">COURSES : </h6> */}
        <div className="careers_box rounded-2 border mx-auto mt- p-3 mb-4">
          <h6 className="fs_lg">COURSES : </h6>
          <ul className="d-flex flex-wrap align-items-center">
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              Graphics Designer
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              Tally
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              MS Office
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              Office Executive
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              Office Operation Executive
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              AI
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              Typing (Eng.+Hindi)
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              English Spoken
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              Shorthand Typing
            </li>
            <li className="ff_inter pe-5 mt-2 list_type text-center text_dark_grey fs_lg mb-0">
              Steno
            </li>
          </ul>
        </div>
        {/* </div> */}
        <form onSubmit={submitted}>
          <div className="row">
            <div className="col-12">
              <select
                required
                className="w-100  p-3 py-2 careers_input"
                onChange={(e) => setValue({ ...value, center: e.target.value })} // Update to center
                value={value.center}
              >
                <option value="">Select Center</option>
                <option value="Chira Chas (Bokaro)">Chira Chas (Bokaro)</option>
                <option value="Chandankiyari (Bokaro)">
                  Chandankiyari (Bokaro)
                </option>
                <option value="Chas College (Bokaro)">
                  Chas College (Bokaro)
                </option>
                <option value="Bermo (Bokaro)">Bermo (Bokaro)</option>
                <option value="Nawadih (Bokaro)">Nawadih (Bokaro)</option>
              </select>
            </div>
            <div className="col-md-6 mt-4">
              <input
                required
                className="w-100 careers_input"
                type="text"
                placeholder="Name"
                onChange={(e) => setValue({ ...value, name: e.target.value })}
                value={value.name}
              />
            </div>
            <div className="col-md-6 mt-4">
              <input
                required
                className="w-100 careers_input"
                type="text"
                placeholder="Father Name"
                onChange={
                  (e) => setValue({ ...value, f_name: e.target.value }) // Update to f_name
                }
                value={value.f_name}
              />
            </div>
          </div>
          <div className="w-100 mt-4">
            <input
              required
              className="w-100 careers_input"
              type="number"
              min={0}
              placeholder="Mob No."
              onChange={(e) => setValue({ ...value, phone: e.target.value })} // Update to phone
              value={value.phone}
            />
          </div>
          <div className="w-100 mt-4">
            <select
              required
              className="w-100 p-3 py-2 careers_input"
              onChange={(e) => setValue({ ...value, category: e.target.value })} // Update to category
              value={value.category}
            >
              <option value="">Select Category</option>
              <option value="AC">AC</option>
              <option value="BC">BC</option>
              <option value="SC">SC</option>
              <option value="ST">ST</option>
              <option value="OBC">OBC</option>
              <option value="General">General</option>
            </select>
          </div>
          <div className="w-100 mt-4">
            <input
              required
              className="w-100 careers_input"
              type="text"
              placeholder="Address"
              onChange={(e) => setValue({ ...value, address: e.target.value })}
              value={value.address}
            />
          </div>
          <div className="w-100 mt-4">
            <input
              required
              className="w-100 careers_input"
              type="text"
              placeholder="Pin Code"
              onChange={(e) => setValue({ ...value, pinCode: e.target.value })}
              value={value.pinCode}
            />
          </div>
          <div className="w-100 mt-4">
            <input
              required
              min={0}
              className="w-100 careers_input"
              type="number"
              placeholder="Aadhar Number"
              onChange={(e) => setValue({ ...value, adharNo: e.target.value })}
              value={value.adharNo}
            />
          </div>

          <div className="mt-4">
            <label className="ff_inter fs-6 text_dark_grey">
              Upload Your Aadhar Front Image
            </label>
            <input
              type="file"
              ref={frontFileInputRef}
              required
              onChange={(e) => handleAadharFileChange(e, "front")}
              accept=".jpg, .jpeg, .png"
              className="form-control"
            />
          </div>
          <div className="mt-4">
            <label className="ff_inter fs-6 text_dark_grey">
              Upload Your Aadhar Back Image
            </label>
            <input
              type="file"
              ref={backFileInputRef}
              onChange={(e) => handleAadharFileChange(e, "back")}
              accept=".jpg, .jpeg, .png"
              className="form-control"
            />
          </div>
          <button
            type="submit"
            className="mt-4 btn_login text-white w-100 ff_inter bg_gradient border-0 p-2 px-lg-3 py-lg-2"
          >
            Register Now
          </button>
          {studentId && (
            <div className="alert alert-success mt-4">
              Your registration was successful. Your Registration ID is:
              <strong> {studentId}</strong>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResignationFrom;
