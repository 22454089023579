import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import "../index.css";
import Home from "../pages/home/index";
import Janhavi from "../pages/youtuber-page/Jhanvi";
import Janhavi1 from "../pages/youtuber-page/Jhanvi1";
import Janhavi2 from "../pages/youtuber-page/Jhanvi2";
import Janhavi3 from "../pages/youtuber-page/Jhanvi3";
import Janhavi4 from "../pages/youtuber-page/Jhanvi4";
import Janhavi5 from "../pages/youtuber-page/Jhanvi5";
import BookDetail from "../pages/bookdetail/index";
import Neethome from "../pages/neethome/index";
import Schoolhome from "../pages/schoolhome/index";
import Skillhome from "../pages/skillhome/index";
import Defencehome from "../pages/defencehome/index";
import Testserieshome from "../pages/testserieshome/index";
import FreeTestSeries from "../pages/testserieshome/FreeTestSeries";
import Login from "../pages/auth/login";
import SkillLogin from "../pages/auth/login_skill";
import SchoolRegister from "../pages/auth/school_register";
import ChangeNumber from "../pages/auth/change_number";
import Register from "../pages/auth/register";
import Welcome from "../pages/auth/Welcome";
// import OTPverify from '../pages/auth/OTP-verify';
import Books from "../pages/bookhome";
// import AllCourse from "../pages/allcourses";
import AllCourse from "../pages/AllCourses/AllCourses";
import TrendingCourses from "../pages/TrendingCourse/TrendingCourses";
import FreeStudyMaterial from "../pages/FreeStudyMaterial/FreeStudyMaterialHero";
import AllBook from "../pages/AllBooks/AllBook";
import All_Test_Series from "../pages/AllTestSeries/All_Test_Series";
import Our_Institutes from "../pages/OurInstitutes/Our_Institutes";
import InstitutesData from "../components/institutesdata/InstitutesData";
import InstitutesAddress from "../components/institutesdata/InstitutesAddress";
import RandomCourses from "../pages/AllCourses/RandomCourses";
import PaymentVerification from "../pages/coursedetail/payment_verification";
import Privacy from "../pages/footerpages/privacypolicy";
import Disclaimer from "../pages/footerpages/Disclaimer";
import PageNotFound from "../pages/404/index";
import Refund from "../pages/footerpages/refund";
import Careers from "../pages/footerpages/careers";
import CurentAffairs from "../pages/freeResources/CurentAffair";
import Test from "../pages/freeResources/Test";
import FreeEbook from "../pages/freeResources/FreeEbook";
import BoostYourSkill from "../pages/freeResources/BoostYourSkill";
import Terms from "../pages/footerpages/terms";
import About from "../pages/footerpages/aboutus";
import ResignationFrom from "../pages/footerpages/ResignationFrom";
import Contact from "../pages/footerpages/contact_us";
import SearchResult from "../pages/searchresult/index";
import SchoolDetail from "../pages/schooldetail/index";
import Downloadfile from "../pages/apk/index";
import Testseriesdetail from "../pages/testseriesdetail/index";
import TestRegister from "../pages/test/test_register";
import TestResult from "../pages/test/result";
import ExamRegister from "../pages/test/exam_register";
import AppAbout from "../pages/app/app-aboutus";
import AppRefund from "../pages/app/app-refund";
import AppPrivacy from "../pages/app/app-privacy";
import AppTerms from "../pages/app/app-term";
import CModal from "../pages/modal/index";
import MyCourses from "../pages/dashboard/MyCourses";
import MyTestSeries from "../pages/dashboard/MyTestSeries";
import CourseVideos from "../pages/dashboard/CourseVideos";
import ViewVideo from "../pages/dashboard/ViewVideo";
import LiveClasses from "../pages/dashboard/LiveClasses";
import LiveStreaming from "../pages/dashboard/LiveStreaming";
import DCWU from "../pages/DCWU";
import TestList from "../pages/dashboard/TestList";
import PlayTest from "../pages/dashboard/PlayTest";
import IframeCourse from "../pages/coursedetail/iframe_course";

// CSC Route
import MyCoursesWeb from "../pages/dashboard/CSC/MyCourses";
import CourseVideosWeb from "../pages/dashboard/CSC/CourseVideos";
import LiveClassesWeb from "../pages/dashboard/CSC/LiveClasses";
import LiveStreamingWeb from "../pages/dashboard/CSC/LiveStreaming";
import ViewVideoWeb from "../pages/dashboard/CSC/ViewVideo";

// Skill Route
import SkillCoursesWeb from "../pages/dashboard/skill/MyCourses";
import SkillCourseVideosWeb from "../pages/dashboard/skill/CourseVideos";
import SkillViewVideoWeb from "../pages/dashboard/skill/ViewVideo";
import SkillCourses from "../pages/SkillCourses";

// ********************************************
import Header from "../include/header/Header";
import Footer from "../include/footer/footer";
import StateData from "../pages/statedata/StateData";
import AllCourses from "../components/allstatedata/AllCourses";
import AllRecorded from "../components/allstatedata/AllRecorded";
import Allbooks from "../components/allstatedata/AllBooks";
import SingleCourse from "../components/singlestatedata/SingleCourse";
import SingleBook from "../components/singlestatedata/SingleBook";
import RasCourses from "../pages/dashboard/RasCourses";
import TestSeries from "../components/StateTest/TestSeries";
import AttemptTestSeries from "../components/StateTest/AttemptTestSeries";
import AllTests from "./allstatedata/AllTests";
import LatestTestSeries from "../pages/latestTestSeries/LatestTestSeries";

const WebRoute = () => {
  const [loginPopup, setLoginPopup] = useState(false);
  const location = useLocation();

  return (
    <>
      {/* ******************************************************************************* */}
      <Header
        location={location}
        loginPopup={loginPopup}
        setLoginPopup={setLoginPopup}
      />
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/home" element={<Home />} status={300} />
        <Route path="/:stateName/:id" element={<StateData />} />
        <Route
          path="/:stateName/:id/all-live-course"
          element={<AllCourses />}
        />
        <Route
          path="/:stateName/:id/all-recorded-course"
          element={<AllRecorded />}
        />
        <Route path="/:stateName/:id/all-test-series" element={<AllTests />} />
        <Route path="/:stateName/:id/all-books" element={<Allbooks />} />
        <Route
          path="/course-detail/:course_url/:courseId"
          element={<SingleCourse setLoginPopup={setLoginPopup} />}
        />
        <Route
          path="/book-detail/:title/:bookId"
          element={<SingleBook setLoginPopup={setLoginPopup} />}
        />
        <Route
          path="/test-series/:stateName/:id"
          element={<TestSeries setLoginPopup={setLoginPopup} />}
        />
        <Route
          path="/attempt-test-series/:stateName/:id"
          element={<AttemptTestSeries setLoginPopup={setLoginPopup} />}
        />
        <Route path="/latest-test-series" element={<LatestTestSeries />} />

        {/* ******************************************************************************* */}

        <Route path="/test-series" element={<Testserieshome />} />
        <Route path="/free-test-series" element={<FreeTestSeries />} />
        <Route path="/skill" element={<Skillhome />} />
        <Route path="/school" element={<Schoolhome />} />
        <Route path="/books" element={<Books />} />
        <Route path="/all-course" element={<AllCourse />} />
        <Route path="/Trending-Courses" element={<TrendingCourses />} />
        <Route
          path="/free-study-material"
          element={<FreeStudyMaterial setLoginPopup={setLoginPopup} />}
        />
        <Route path="/all-book" element={<AllBook />} />
        <Route path="/all-test-series" element={<All_Test_Series />} />
        <Route path="/our-institutes" element={<Our_Institutes />} />
        <Route path="/institutesdata" element={<InstitutesData />} />
        <Route path="/institutesaddress" element={<InstitutesAddress />} />
        <Route path="/random-courses" element={<RandomCourses />} />
        <Route path="/search-result" element={<SearchResult />} />
        <Route path="/login" element={<Login />} />
        <Route path="/skill-login" element={<SkillLogin />} />
        <Route path="/defence" element={<Defencehome />} />
        <Route path="/neet" element={<Neethome />} />
        <Route path="/register" element={<Register />} />
        <Route path="/welcome" element={<Welcome />} />
        {/* <Route path="/otp-verify" element={<OTPverify />}/>  */}
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/registration-form" element={<ResignationFrom />} />
        <Route path="/terms-condition" element={<Terms />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/careers" element={<Careers />} />
        <Route
          path="/curent-affair"
          element={<CurentAffairs setLoginPopup={setLoginPopup} />}
        />
        <Route path="/test" element={<Test setLoginPopup={setLoginPopup} />} />
        <Route
          path="/free-ebook"
          element={<FreeEbook setLoginPopup={setLoginPopup} />}
        />
        <Route
          path="/boost_your_skill"
          element={<BoostYourSkill setLoginPopup={setLoginPopup} />}
        />
        {/* for app */}
        <Route path="/refund" element={<AppRefund />} />
        <Route path="/aboutus" element={<AppAbout />} />
        <Route path="/privacy" element={<AppPrivacy />} />
        <Route path="/terms" element={<AppTerms />} />
        {/* for app */}
        <Route path="/contactus/:id" element={<Contact />} />
        <Route path="/test-register" element={<TestRegister />} />
        <Route path="/test-series/:name/:id" element={<Testseriesdetail />} />
        <Route path="/contactus/" element={<Contact />} />
        <Route path="/school-register" element={<SchoolRegister />} />
        <Route path="/change-number" element={<ChangeNumber />} />
        <Route path="/download-v1" element={<SchoolRegister />} />
        <Route path="/school-detail/:schoolId" element={<SchoolDetail />} />
        <Route path="/download-file" element={<Downloadfile />} />
        <Route path="/payment-verification" element={<PaymentVerification />} />
        <Route path="*" element={<PageNotFound />} status={404} />
        <Route path="/test-result" element={<TestResult />} />
        <Route path="/page-not-found" element={<PageNotFound />} status={404} />
        <Route path="/exam-register" element={<ExamRegister />} />
        <Route path="/modal" element={<CModal />} />

        <Route
          path="my-courses"
          element={<MyCourses setLoginPopup={setLoginPopup} />}
        />
        <Route
          path="my-test-series"
          element={<MyTestSeries setLoginPopup={setLoginPopup} />}
        />
        <Route path="/ras-courses" element={<RasCourses />} />
        <Route
          path="course-videos/:course_id/:subject_id"
          element={<CourseVideos />}
        />
        <Route path="live-classes" element={<LiveClasses />} />
        <Route path="live-streaming/:id" element={<LiveStreaming />} />
        <Route
          path="view-video/:id/:course_id/:subject_id"
          element={<ViewVideo />}
        />
        <Route path="test-list/:course_id/:subject_id" element={<TestList />} />
        <Route path="play-test" element={<PlayTest />} />

        {/* CSC routes */}
        <Route path="my-courses-web/:token" element={<MyCoursesWeb />} />
        <Route
          path="course-videos-web/:token/:course_id/:subject_id"
          element={<CourseVideosWeb />}
        />
        <Route path="live-classes-web/:token" element={<LiveClassesWeb />} />
        <Route
          path="live-streaming-web/:token/:id"
          element={<LiveStreamingWeb />}
        />
        <Route
          path="view-video-csc/:token/:id/:course_id/:subject_id"
          element={<ViewVideoWeb />}
        />

        {/* CSC routes */}
        <Route path="skill-courses-web/:token" element={<SkillCoursesWeb />} />
        <Route
          path="skill-course-videos-web/:token/:course_id/:subject_id"
          element={<SkillCourseVideosWeb />}
        />
        <Route
          path="skill-view-video-csc/:token/:id/:course_id/:subject_id"
          element={<SkillViewVideoWeb />}
        />

        <Route path="DCWU" element={<DCWU />} />
        <Route path="skill-courses" element={<SkillCourses />} />
        <Route path="iframe-course/:name" element={<IframeCourse />} />

        {/*Youtuber route page*/}
        <Route path="janhavi-course" element={<Janhavi />} />
        <Route path="janhavi-course-1" element={<Janhavi1 />} />
        <Route path="janhavi-course-2" element={<Janhavi2 />} />
        <Route path="janhavi-course-3" element={<Janhavi3 />} />
        <Route path="janhavi-course-4" element={<Janhavi4 />} />
        <Route path="janhavi-course-5" element={<Janhavi5 />} />
      </Routes>
      <Footer location={location} />
    </>
  );
};

export default WebRoute;
